<template>
  <v-app>
    <v-dialog scrollable v-model="status" max-width="500">
      <v-card v-if="website.is_active">
        <v-card-title class="headline">Unpublish Website</v-card-title>

        <v-card-text>
          You are about to unpublish your website. By doing this the website will no loger be visible.
          Since your data will not be removed unless you delete, You can publish the website whenever you like.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="closeDialog">Disagree</v-btn>

          <v-btn color="green darken-1" text @click="updateWebsite(0)">Agree</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="headline">Publish Website</v-card-title>

        <v-card-text>By publishing the website your website will be visible within a second.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="closeDialog">Disagree</v-btn>

          <v-btn color="green darken-1" text @click="updateWebsite(1)">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
export default {
  name: "website-publish",
  props: ["website", "status"],
  mounted() {
    // console.log(this.website);
  },
  methods: {
    closeDialog() {
      this.$emit("close_dialog");
    },
    updateWebsite(status) {
      this.website.is_active = status
      this.$emit("toggle_status");
    }
  }
};
</script>

<template>
  <div>

    <v-app>

      <div class="row">
        <div class="col-md-4">
          <h3>
            <i class="fa fa-globe"></i>
            {{ siteUrl }}
          </h3>
        </div>
        <div class="col-md-8 text-right">
          <a v-if="website.subscription.status" href="#" class=" mr-5"><i class="fa fa-calendar-alt"></i>
            {{ website.subscription.expire_at }}</a>
          <a :href="'http://'+siteUrl" target="_blank" class=" mr-5"><i class="fa fa-link"></i> View
            Website</a>
          <a href="#" @click.prevent="editPage()" class=" mr-5"><i class="fa fa-edit"></i> Edit Website</a>
          <a href="#" class="" @click="deleteWebsite"><i class="fa fa-trash"></i> Trash</a>
        </div>
      </div>

      <!--      <pre>-->
      <!--        {{website}}-->
      <!--      </pre>-->
      <div class="row">
        <div class="col-md-12">
          <v-card
              class="mx-auto"
              outlined
          >
<!--            <v-img-->
<!--                :src="website.template_image"-->
<!--                height="400px"-->
<!--            ></v-img>-->

            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Your Business Info :
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ siteUrl }}
                </v-list-item-title>
<!--                <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle>-->
                <div class="kt-portlet kt-portlet--height-fluid">
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-widget kt-widget--project-1">
                      <div class="  p-2">
                    <div class="kt-widget__body">
                      <div class="kt-widget__stats">
                        <div class="kt-widget__item d-flex align-items-center">
                          <div class="kt-widget__label">
                            <!--                      <pre>{{item}}</pre>-->
                            <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10"
                                  v-if="!website.plan_id">Free plan</span>
                            <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10"
                                  v-else>{{ website.subscription.title | '' }}</span>
                          </div>
                          <div class="kt-widget__label" v-if="website.is_custom_domain">

                            <span
                                class="btn btn-label-warning btn-sm btn-bold btn-upper kt-margin-r-10">Custom Domain</span>
                          </div>
                          <div class="kt-widget__label" v-if="website.has_ssl && website.is_custom_domain">
                            <span
                                class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10">SSL Secured</span>
                          </div>
                          <div class="kt-widget__label" v-if="website.setup_steps<5">
                            <span class="btn btn-label-danger btn-sm btn-bold btn-upper kt-margin-r-10">Incomplete Website Setup</span>
                          </div>
                          <div class="kt-widget__label" v-if="website.setup_steps<5">
                            <a class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10"
                               @click.prevent="checkSteps(website.setup_steps,(website.domain_name && website.is_custom_domain)?website.domain_name:website.sub_domain_name)">Resume
                              Setup</a>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-2">
                        <div class="col-md-2">
                          <span class="kt-widget__subtitel">Storage</span>
                        </div>
                        <div class="col-md-7">
                          <b-progress v-if="website.volume_used" :variant="'success'"
                                      :value="website.volume_used.percentage_used"
                                      show-progress class="mb-3"></b-progress>
                          <b-progress v-else :value="value" :variant="'success'" show-progress
                                      class="mb-3"></b-progress>
                        </div>
                        <div class="col-md-3">
                          <strong v-if="website.volume_used">{{
                              website.volume_used.percentage_used.toFixed(2)
                            }} %</strong>
                          <strong v-else>0%</strong>
                        </div>
                      </div>

                      <div class="kt-widget__stats kt-margin-t-5">

                        <div class="kt-widget__footer">
                          <div class="kt-widget__wrapper">
                            <div class="kt-widget__section">
                              <div class="kt-widget__blog">
                                <i class="flaticon2-list-1"></i>
                                <a href="#" class="kt-widget__value kt-font-brand">{{
                                    website.total_page ?
                                        website.total_page : 0
                                  }} Pages</a>
                              </div>

                              <div class="kt-widget__blog pl-2">
                                <i class="flaticon2-talk"></i>
                                <a href="#" class="kt-widget__value kt-font-brand">{{
                                    website.total_blog ?
                                        website.total_blog : 0
                                  }} Blogs</a>
                              </div>
                              <!-- <div class="kt-widget__blog pl-2">
                                    <i class="flaticon2-list-1"></i>
                                    <a href="#" class="kt-widget__value kt-font-brand">{{ website.total_product ? website.total_product : 0 }} Products</a>
                                  </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <div class="row" v-if=" website && website.subscription.type !='paid'">
        <div class="col-md-12">
          <v-alert border="left" colored-border
                   color="orange accent-4" elevation="2">
            Please upgrade membership to unlock all features. <a href="#" @click.prevent="manageSubscription">Upgrade
            now</a>
          </v-alert>
        </div>

      </div>
      <div class="row">
        <!--   <div class="col-md-6">
              <v-card
                      class="mx-auto text-center"
                      color="blue"
                      dark
                      max-width="600"
              >
                  <v-card-text>
                      <v-sheet color="rgba(0, 0, 0, .12)">
                          <v-sparkline
                                  :value="value"
                                  :value="value"
                                  color="rgba(255, 255, 255, .7)"
                                  height="100"
                                  padding="24"
                                  stroke-linecap="round"
                                  smooth
                          >
                              <template v-slot:label="item">
                                  ${{ website.value }}
                              </template>
                          </v-sparkline>
                      </v-sheet>
                  </v-card-text>

                  <v-card-text>
                      <div class="display-1 font-weight-thin">
                          Total Sales Rs. 2,35,000
                      </div>
                  </v-card-text>


              </v-card>
          </div>
          <div class="col-md-6">
              <div class="row">
                  <div class="col-md-6">
                      <div class="card card-custom bgi-no-repeat gutter-b"
                           style="height: 175px; background-color: #ffffff; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
                          <div class="card-body d-flex align-items-center">
                              <div class="text-center">
                                  <h3 class="text-black line-height-lg mb-5"> Life TIme Sales <br>
                                  </h3>
                                  <h2>
                                      Rs. 2,35,000
                                  </h2>

                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                      <div class="card card-custom bgi-no-repeat gutter-b"
                           style="height: 175px; background-color: #ffffff; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
                          <div class="card-body d-flex align-items-center">
                              <div class="text-center">
                                  <h3 class="text-black line-height-lg mb-5"> Total Customer <br>
                                  </h3>
                                  <h2>
                                      Rs. 2,35,000
                                  </h2>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->
        <div class="col-md-3" v-if="!website.is_custom_domain ">
          <div class="col-xl-12">
            <div class="card card-custom bgi-no-repeat gutter-b"
                 style="height: 175px; background-color: rgb(180, 255, 181); background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
              <div class="card-body d-flex align-items-center">
                <div>
                  <h3 class="text-black line-height-lg mb-5"> Connect a <br>Domain </h3>
                  <router-link class="btn btn-success text-white px-6 py-3"
                               style="background: #1976d2"
                               :to="{ name: 'manage-custom-domains', params: { domainname: siteUrl }}">
                    Let's do this
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="col-xl-12">
            <div class="card card-custom bgi-no-repeat gutter-b"
                 style="height: 175px; background-color: #ffffff; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
              <div class="card-body d-flex align-items-center">
                <div>
                  <h3 class="text-black line-height-lg mb-5" v-if="!website.plan_id"> Manage <br>Subscription </h3>
                  <h3 class="text-black line-height-lg mb-5" v-else v-text="website.subscription.title"><br></h3>
                  <a href="#" @click.prevent="manageSubscription"
                     class="btn btn-success text-white px-6 py-3" style="background: #1976d2">View/Upgrade</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="col-xl-12">
            <div class="card card-custom bgi-no-repeat gutter-b"
                 style="height: 175px; background-color: rgb(180, 255, 181); background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
              <div class="card-body d-flex align-items-center">
                <div>
                  <h3 class="text-black line-height-lg mb-5"> Having Trouble <br>Setting Up ? </h3>
                  <router-link class="btn btn-secondary text-white px-6 py-3"
                               style="background: #1976d2"
                               :to="{ name: 'website-troubleshoot', params: { domain_name: siteUrl }}">
                    Use Troubleshoot
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-if="!setting.sell_product_online">
          <div class="col-xl-12">
            <div class="card card-custom bgi-no-repeat gutter-b"
                 style="height: 175px; background-color: #ffffff; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
              <div class="card-body d-flex align-items-center">
                <div>
                  <h3 class="text-black line-height-lg mb-5"> Looking to sell <br>products online ?
                  </h3>
                  <router-link :to="{ name: 'manage-settings', params: { domainname: siteUrl }}"
                               class="btn btn-secondary text-white px-6 py-3"
                               style="background: #1976d2">Enable Store
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-else>
          <div class="col-xl-12">
            <div class="card card-custom bgi-no-repeat gutter-b"
                 style="height: 175px; background-color: #ffffff; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
              <div class="card-body d-flex align-items-center">
                <div>
                  <h3 class="text-black line-height-lg mb-5"> Looking to sell <br>products online ?
                  </h3>
                  <router-link to=""
                               class="btn btn-secondary text-white px-6 py-3"
                               style="background: #1976d2">Add products
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-xl-12">
            <div class="card card-custom bgi-no-repeat gutter-b"
                 style="height: 175px; background-color: #1976d2; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url(&quot;/media/svg/patterns/taieri.svg&quot;);">
              <div class="card-body d-flex align-items-center justify-content-between">
                <div>
                  <h3 class="text-white line-height-lg mb-5"> Need assistance for setup?
                  </h3>
                  <h5 class="text-white">Not sure where to start ? Our professional team member can help you through
                    setup</h5>

                </div>
                <a href="#" @click="manageServices"
                   class="btn btn-secondary text-white px-6 py-3 pull-right"
                   style="background: #1976d2">Request Service
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h3>Quick Tips</h3>
          <b-list-group>
            <b-list-group-item>Setup
              <router-link :to="{ name: 'website-fb-chat', params: { domain_name: siteUrl }}">Fb Chat
              </router-link>
              to increase customer engagement
            </b-list-group-item>
            <b-list-group-item>Run target ads using
              <router-link :to="{ name: 'website-pixel', params: { domain_name: siteUrl }}">Facebook
                Pixel
              </router-link>
            </b-list-group-item>
            <b-list-group-item>Track your visitors using
              <router-link :to="{ name: 'website-google-analytics', params: { domain_name: siteUrl }}">
                Google Analytics
              </router-link>
            </b-list-group-item>
            <b-list-group-item>Secure your contact form by adding
              <router-link :to="{ name: 'manage-settings', params: { domainname: siteUrl }}">Google
                Recaptcha
              </router-link>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col-md-6">
          <h3>Ecommerce Tips</h3>
          <b-list-group>
            <b-list-group-item>
              <router-link :to="{ name: 'add-product'}">Add</router-link>
              product with decent size title
            </b-list-group-item>
            <b-list-group-item>Sell your product in different
              <router-link :to="{ name: 'website-currency', params: { domain_name: siteUrl }}">Currency
              </router-link>
            </b-list-group-item>
            <b-list-group-item>Setup
              <router-link :to="{ name: 'shipping', params: { domainname: siteUrl }}">shipment methods
              </router-link>
              or a
              <router-link :to="{ name: 'shipping', params: { domainname: siteUrl }}">pickup option
              </router-link>
              for order
            </b-list-group-item>
            <b-list-group-item>Setup your
              <router-link :to="{ name: 'shipping-zone', params: { domainname: siteUrl }}">delivery
                zones
              </router-link>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <v-dialog scrollable v-model="dialog" max-width="900">
        <v-card>
          <v-card-title class="headline">Connect your Domain</v-card-title>
          <v-card-text>
            <div>
              <div class="col-md-12">
                <div class="row">
                  <!-- <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <h4>Existing Domain</h4>
          <p>You can connect your existing domain name to our website or ecommerce plan. You will need admin privilege of your domain to do so.</p>
</div>-->
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="kt-portlet">
                      <!-- <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">Connect your domain</h3>
          </div>
</div>-->
                      <div class="kt-portlet__body">
                        <custom-domain :domain_name="siteUrl"></custom-domain>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
            <h4>Change to sub domain</h4>
            <p>You can connect to any sub doamin and take advantage of free surfing.</p>
          </div>
          <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
              <div class="kt-portlet">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">Connect with subdomain</h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <sub-domain :domain_name="siteUrl" @close="closeConnectDomain"></sub-domain>
              </div>
            </div>
          </div>
</div>-->
              </div>
            </div>
          </v-card-text>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="dialog = false">Agree</v-btn>
</v-card-actions>-->
        </v-card>
      </v-dialog>
      <website-status :website="website" :status="website_status_dialog" @close_dialog="closeWebsiteStatusDialog"
                      @toggle_status="toggleStatus"></website-status>
    </v-app>
  </div>
</template>

<script>
import CustomDomain from "./CustomDomain";
import WebsiteStatus from "./WebsiteStatus";
import SubDomain from "./SubDomain";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import moment from "moment";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
import Website from "@/services/Websites/WebsiteService";

const setting = new WebsiteSetting();
const websiteService = new Website();

export default {
  name: "manage-websites",
  components: {
    CustomDomain,
    KTPortlet,
    SubDomain,
    WebsiteStatus
  },
  data() {
    return {
      website_status_dialog: false,
      siteUrl: null,
      membership_dialog: false,
      website: null,
      dialog: false,
      defaultVolumeUsed: 0,
      setting: {},
      value: [
        423,
        446,
        675,
        510,
        590,
        610,
        760,
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Website Dashboard",
      route: "/websites"
    }]);
    this.siteUrl = this.$route.params.domainname;
    this.getWebsite();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("MMM DD YYYY");
    },
    getWebsite() {
      websiteService.get(this.siteUrl).then(res => {
        this.website = res.data.website;
        this.getSetting();
      });
    },
    getSetting() {
      setting
          .settingByWebsiteId(this.website.id)
          .then(response => {
            this.setting = response.data;
            // console.log(response.data);
            // console.log(this.setting)
            this.checkSteps(this.setting.setup_steps);
          })
          .catch(err => {
            // console.log(err);
          });
    },
    checkSteps(step) {
      let url = null;
      let serverUrl = process.env.VUE_APP_SERVER_URL;
      switch (step) {
        case 1:
          url = serverUrl + "website/" + this.siteUrl + "/details";
          break;
        case 2:
          url = serverUrl + "website/" + this.siteUrl + "/available-pages";
          break;
        case 3:
          url = serverUrl + "website/" + this.siteUrl + "/blog";
          break;
        case 4:
          url = serverUrl + "website/" + this.siteUrl + "/currency";
          break;
        case 5:
          url = null;
          break;
        case 6:
          url = null;
        case 7:
          url = null;
          break;
        default:
          url = serverUrl + "website/" + this.siteUrl + "/type";
          break;
      }
      if (url && url !== undefined && url !== null) {
        window.location.href = url;
      }
    },
    manageSubscription() {
      window.location.href =
          process.env.VUE_APP_SERVER_URL + this.siteUrl + "/pricing";
    }, manageServices() {
      window.location.href =
          process.env.VUE_APP_SERVER_URL + this.siteUrl + "/services";
    },
    openDomainDialog() {
      this.dialog = true;
    },
    closeDomainDialog() {
      this.dialog = false;
    },
    dialogClose() {
      this.membership_dialog = false;
    },
    closeConnectDomain() {
      this.dialog = false;
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    openWebsiteStatusDialog() {
      this.website_status_dialog = true;
    },
    closeWebsiteStatusDialog() {
      this.website_status_dialog = false;
      this.getWebsite();
    },
    toggleStatus(data) {
      websiteService
          .publish(this.siteUrl)
          .then(response => {
            this.$snotify.success("Changes updated successfully");
            this.closeWebsiteStatusDialog();
          })
          .catch(error => {
            // console.log(error)
          });
    },
    editPage() {
      let id = this.setting.home_page_id;
      if (this.website.is_gridless_builder == 1) {
        let url =
            process.env.VUE_APP_SERVER_URL +
            "pagebuilder/" +
            this.siteUrl +
            "/website/editor/gridless/" +
            id + "#builder";
        window.open(url, "_blank");
      } else {
        let url =
            process.env.VUE_APP_SERVER_URL +
            "pagebuilder/" +
            this.siteUrl +
            "/website/editor/" +
            id +
            "/update#/pagebuilder";
        window.open(url, "_blank");
      }

    },
    deleteWebsite() {
      this.$confirm({
        message: `Deleting Website", "Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            websiteService.delete(this.siteUrl).then(res => {
              this.$snotify.success("Website deleted successully");
              this.$router.push({
                name: "website-dashboard"
              });
            });
          }
        }
      });
    }
  }
  // computed: {
  //   siteUrl() {
  //     return this.$route.params.domainname;
  //   }
  // }
};
</script>

<style scoped>
.free_plan_alert {
  padding: 0px;
  padding-right: 10px;
  color: #363636 !important;
  font-weight: 300;
  font-size: 1.1rem;
}
</style>

<template>
    <v-app>
             <v-card>
                <v-card-text>
                    <b-form class="kt-form" @submit.stop.prevent="oldDomain">
                        <b-input-group prepend="www." class="mb-2 mr-sm-2 mb-sm-0">
                            <b-input
                                id="inline-form-input-old-domain"
                                placeholder="your-domain-name.com"
                                name="example-input-1"
                                v-model="$v.form.oldDomain.$model"
                                :state="validateState('oldDomain')"
                            ></b-input>
                            <b-form-invalid-feedback id="input-0-live-feedback">{{oldDomainMsg}}
                            </b-form-invalid-feedback>
                        </b-input-group>
                        <br/>

                        <v-btn
                            v-if="oldDomainMsg == 'Already added need verification' || oldDomainMsg =='Already verified'"
                            type="submit"
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            :loading="isBusy"
                            @click.prevent="verifyNow($v.form.oldDomain.$model)"
                        >Verify Now
                        </v-btn>

                        <v-btn
                            v-else-if="oldDomainMsg == null"
                            type="submit"
                            color="white--text v-btn theme--light elevation-2 v-size--large primary"
                            class="btn-save-popup"
                            text
                            :loading="isBusy"
                        >Continue
                        </v-btn>
                    </b-form>
                </v-card-text>
            </v-card>
    </v-app>

</template>
<script>
    import Website from "@/services/Websites/WebsiteService";
    import {mapState} from "vuex";
    import ApiService from "@/common/api.service";
    import {validationMixin} from "vuelidate";
    import {email, required, minLength, sameAs} from "vuelidate/lib/validators";
    import DomainVerificationService from "@/services/domain/DomainVerificationService";

    const WebsiteService = new Website();
    const verificationService = new DomainVerificationService();

    export default {
        name: "custom-domain",
        // props: ["domain_name"],
        mixins: [validationMixin],
        data() {
            return {
                isBusy: false,
                oldDomainMsg: null,
                form: {
                    oldDomain: ""
                },
                website: {}
            };
        },
        validations: {
            form: {
                oldDomain: {
                    required,
                    async isUnique(value) {
                        if (value === "") return true;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                verificationService
                                    .verifyDomain(this.$v.form.oldDomain.$model)
                                    .then(response => {
                                        this.oldDomainMsg = response.data.msg;
                                        resolve(response.data.status);
                                    });
                            }, 100);
                        });
                    }
                }
            }
        },
        mounted() {
            if (this.domain_name != null && this.domain_name != undefined)
                this.getWebsite();
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            }),
            siteUrl() {
                return this.$route.params.domainname;
            },
            domain_name() {
                return this.$route.params.domainname;
            }
        },
        methods: {
            getWebsite() {
                WebsiteService.get(this.domain_name).then(res => {
                    this.website = res.data.website;
                });
            },
            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    newDomain: null
                };
                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            oldDomain() {
                this.$v.form.oldDomain.$touch();
                if (this.$v.form.oldDomain.$anyError) {
                    return;
                }
                const oldDomain = this.$v.form.oldDomain.$model;
                let website = {
                    domain_name: oldDomain,
                    is_custom_domain: 1
                };
                // set spinner to submit button

                setTimeout(() => {
                    if (this.domain_name != null && this.domain_name != undefined) {
                        this.isBusy = true;
                        this.update(website);
                    } else {
                        this.isBusy = true;
                        this.save(website);
                    }
                }, 2000);
            },
            save(website) {

                verificationService
                    .store(website)
                    .then(res => {
                        this.isBusy = false;
                        website = {};
                        this.$router.push({
                            name: "domain-verfication",
                            params: {domain_name: res.data.domain.domain_name}
                        });
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            update(website) {

                website.is_custom_domain = 1;
                WebsiteService.update(this.domain_name, website)
                    .then(res => {
                        this.isBusy = false;
                        this.save(website);
                    }).catch(err => {
                }).finally(() => (this.isBusy = false));
            },
            verifyNow(domain) {
                this.$router.push({
                    name: "domain-verfication",
                    params: {domain_name: domain}
                });
            }
        }
    };
</script>
<style scoped>
    .card-header a {
        font-size: 1.25em;
        text-align: left;
    }
</style>

import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class DomainVerificationService {

    verifyDomain(domainName) {
        let url = API_URL + 'user/validate-domain/' + domainName;
        return apiService.get(url);
    }

    store(data) {
        let url = API_URL + 'user/domain-verification';
        return apiService.post(url, data);
    }

    show(domainName) {
        let url = API_URL + 'user/domain-verification/' + domainName;
        return apiService.get(url);
    }

    verifyDns(domainName, type) {
        let url = API_URL + 'user/domain/verify/dns/' + domainName + '/' + type;
        return apiService.get(url);
    }

    validateDomain(domainName) {
        let url = API_URL + 'user/domain/validate/domain/' + domainName ;
        return apiService.get(url);
    }

    verifyNameServer(domainName, type) {
        let url = API_URL + 'user/domain/verify/ns/' + domainName + '/' + type;
        return apiService.get(url);
    }

    getByUser(index = null) {
        let url = API_URL + 'user/managed-dns';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    getNonVerifiedDomains(){
        let url = API_URL + 'user/non-verified/domains';
        return apiService.get(url);
    }

    delete(domain_name){
        let url =  `${API_URL}user/domain-verification/${domain_name}/delete`;
        return apiService.delete(url);
    }
}

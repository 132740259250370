<template>
  <b-form class="kt-form">
    <b-input-group prepend="https://" :append="app_url" class="mb-2 mr-sm-2 mb-sm-0">
      <b-input
        id="inline-form-input-free-domain"
        placeholder="your-domain-name"
        name="example-input-0"
        v-model="$v.form.freeDomain.$model"
        :state="validateState('freeDomain')"
      ></b-input>
      <b-form-invalid-feedback id="input-0-live-feedback">{{errorMsg}}</b-form-invalid-feedback>
    </b-input-group>
    <br />

    <v-btn
      type="button"
      color="white--text v-btn theme--light elevation-2 v-size--large primary"
      class="btn-save-popup"
      text
      :loading="isBusy"
      v-if="status"
      @click.prevent="freeDomain"
    >Continue</v-btn>
  </b-form>
</template>
<script>
import { mapState } from "vuex";
import ApiService from "@/common/api.service";
import Website from "@/services/Websites/WebsiteService";
import DomainVerificationService from "@/services/domain/DomainVerificationService";

const WebsiteService = new Website();
const verificationService = new DomainVerificationService();
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "sub-domain",
  props: ["domain_name"],
  data() {
    return {
      errorMsg: null,
      isBusy: false,
      status: true,
      app_url: null,
      website: null,
      form: {
        freeDomain: ""
      }
    };
  },
  validations: {
    isUniqueDomain: false,
    form: {
      freeDomain: {
        required,
        async isUnique(value) {
          if (value === "") return true;
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              ApiService.post(
                "user/subdomain/validate-domain",
                {
                  sub_domain_name:
                    this.$v.form.freeDomain.$model + "." + this.app_url
                }
              ).then(response => {
                this.status = response.data.status;
                if (!response.data.status) {
                  this.errorMsg = response.data.msg;
                }

                resolve(response.data.status);
              });
            }, 100);
          });
        }
      }
    }
  },
  mounted() {
    this.app_url = process.env.VUE_APP_URL;
    if (this.domain_name != null && this.domain_name != undefined)
      this.getWebsite();
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  methods: {
    getWebsite() {
      WebsiteService.get(this.domain_name).then(res => {
        this.website = res.data.website;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        freeDomain: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    freeDomain() {
      this.$v.form.$touch();
      const freeDomain = this.$v.form.freeDomain.$model;
      if (!this.$v.form.$error) {
        let website = {
          sub_domain_name: freeDomain + "." + this.app_url,
          has_ssl: 1,
          is_custom_domain: 0,
          is_active: 1
        };
        // set spinner to submit button

        // dummy delay
        setTimeout(() => {
          if (this.domain_name != null && this.domain_name != undefined) {
            this.update(website);
          } else {
            this.create(website);
          }
        }, 1000);
      }
    },

    update(website) {
      this.isBusy = true;
      website.is_custom_domain = 0;
      WebsiteService.update(this.domain_name, website)
        .then(res => {
          this.isBusy = false;
          setTimeout(() => {}, 1000);
          submitButton.classList.remove("kt-spinner");
          this.$emit("close");
          this.$router.push({
            name: "manage-websites",
            params: { domainname: website.sub_domain_name }
          });
        })
        .catch(err => {})
        .finally(() => (this.isBusy = false));
    },
    create(website) {
      this.isBusy = true;
      WebsiteService.create(website)
        .then(res => {
          this.isBusy = false;
          this.$snotify.success("Sub domain created");
          setTimeout(() => {
            this.$router.push({
              name: "quicksetup",
              params: { domainname: res.data.website.sub_domain_name }
            });
          }, 1000);
        })
        .catch(err => {})
        .finally(() => (this.isBusy = false));
    },

    verifyNow(domain) {
      this.$router.push({
        name: "domain-verfication",
        params: { domain_name: domain }
      });
    }
  }
};
</script>
